import { ENDPOINTS } from "./endpoints";
import { global } from ":global";

export async function update_project_submission(data) {
  return global.api
    .putAuth(ENDPOINTS.put_submission, data, {
      headers: {
       
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}